<script setup lang="ts">
import { onMounted } from 'vue'

// Define the props using TypeScript
const props = defineProps<{
  hubspotid?: string
}>()

const loadHubspotForm = () => {
  if (props.hubspotid) {
    window.hbspt.forms.create({
      portalId: '9175363',
      formId: props.hubspotid, // Use the hubspotid prop here
      target: '#embedded-form', // Target the div id
    })
  } else {
    console.error('Hubspot ID is missing')
  }
}
onMounted(() => {
  loadHubspotForm()
})
</script>

<template>
  <div>
    <div id="embedded-form" class="text-copy"></div>
  </div>
</template>

<style>
.hs-form-iframe {
  @apply w-full;
}
.hs-input[type='text'],
.hs-input[type='email'],
.hs-input[type='tel'],
textarea.hs-input {
  @apply !w-full rounded-lg border border-grey-dark bg-white px-3 py-3 text-black lg:px-6;
}
textarea.hs-input {
  @apply min-h-52;
}
.hs-form-field {
  @apply mb-3 !w-full md:mb-6;
}
.hs-form-field label {
  @apply block text-grey-darker;
  font-size: 12px;
}
.hs-button {
  @apply mt-6 min-w-32 cursor-pointer rounded-full bg-blue-dark px-6 py-4 text-white transition hover:scale-[1.02] hover:opacity-80 max-sm:w-full;
}
.hs-error-msgs {
  @apply mt-2 text-sm text-red-500;

  label {
    @apply block text-red-500;
  }
}
.hs-richtext {
  @apply text-sm;
}
.hs-form-booleancheckbox p {
  @apply block text-base;
}
.hs-form-booleancheckbox {
  @apply mt-4;
}
.form-columns-2 {
  @apply sm:grid sm:grid-cols-2 sm:gap-3 md:gap-6;
}

fieldset {
  @apply !max-w-full;

  .input {
    @apply !m-0;
  }
}

.hs-fieldtype-file {
  @apply items-center gap-4 md:flex;

  ::-webkit-file-upload-button {
    display: none;
  }

  label {
    @apply mb-2 flex items-center gap-4 rounded-full border border-black bg-white px-6 py-4 text-center text-black transition hover:scale-[1.02];
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='25' fill='none'%3E%3Cpath stroke='%2300030F' d='M10.96 7.583v9.167m0-9.167-4.545 4.263m4.545-4.263 4.622 4.334'/%3E%3Cpath stroke='%23000' d='M3.668 15.833v4.584h14.667v-4.584'/%3E%3C/svg%3E");
      width: 22px;
      height: 25px;
      display: inline-block;
    }
  }
}

.actions {
  text-align: right;
}

.hs-form-booleancheckbox-display {
  input {
    @apply mt-2;
  }
}
</style>
